<template>
  <div class="detail_content" @mousewheel="scrollChange">
    <div class="nav_header">
      <div class="nav_box">
        <div class="leftMenu flex">
          <img src="@/assets/back_icon.png" alt="" @click="$router.go(-1)" />
          <p>{{ contentInfo.title }} <span class="limit-time" v-if="detailInfo.content.min_view_limit">{{ limitTime }}</span></p>
        </div>
        <div class="rightBtn">
          <ul class="flex">
            <li @click="menuState = !menuState">展开目录</li>
            <li
              v-if="detailInfo.prev_page && detailInfo.prev_page.id"
              @click="prevPage(detailInfo.prev_page.id)"
            >
              上一节
            </li>
            <li
              v-if="detailInfo.next_page && detailInfo.next_page.id"
              @click="nextPage(detailInfo.next_page.id)"
            >
              下一节
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="menu_list" v-if="menuState">
        <ul>
          <li
            v-for="item in chapterList"
            :key="item.id"
            @click="changeMenu(item.id)"
            :class="text_course_chapter_id == item.id ? 'active' : ''"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
      <div class="right_cont">
        <p v-html="contentInfo.content"></p>
        <div class="maodian" ref="maodian"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "detail",
  data() {
    return {
      limitTime: '',    //倒计时
      over: false,      //阅读结束
      timerOver: false, //倒计时结束
      detailId: "",
      tabIndex: "1",
      detailInfo: {},
      text_course_chapter_id: "",
      text_course_id: "",
      contentInfo: "",
      chapterList: [],
      menuState: false,
    };
  },
  mounted() {
    this.text_course_chapter_id = this.$route.query.id;
    this.text_course_id = this.$route.query.text_course_id;
    // if (this.detailId) {
    this.getDetail();
    this.handleScroll();
    // }
  },
  methods: {
    getDetail() {
      let parmas = {
        text_course_id: this.text_course_id,
        text_course_chapter_id: this.text_course_chapter_id,
      };
      this.$axios
        .post(`/v1/textCourse/play`, parmas, { useLog: true })
        .then((res) => {
          if (res.code == 1) {
            this.detailInfo = res.data;
            this.contentInfo = res.data.content;
            this.chapterList = res.data.text_course_chapter;

            if (res.data.content.min_view_limit > 0) {
              this.limitTime = res.data.content.min_view_limit + ":00";
              this.countdown(res.data.content.min_view_limit);
            } else {
              this.getRecord();
            }
          }
        });
    },
    //倒计时
    countdown(date) {
      let _this = this;
      let maxtime = date * 60; //一个小时，按秒计算，自己调整!

      let timer = setInterval(function () {
        if (maxtime >= 0) {
          let minutes = Math.floor(maxtime / 60);
          let seconds = Math.floor(maxtime % 60);
          let msg = minutes + ":" + (seconds != 0 ? (seconds<10?'0'+seconds:seconds) : '00');
          _this.limitTime = msg;
          --maxtime;
        } else {
          _this.timerOver = true;
          clearInterval(timer);
          _this.getRecord();
        }
      }, 1000);
    },
    //监听滚动
    scrollChange () {
      window.addEventListener('scroll', this.handleScroll)
    },
    //判断锚点是否出现在可视区
    handleScroll(){
      var exp_height = this.$refs.maodian.offsetTop;
      var clientheight = document.documentElement.clientHeight;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(clientheight+scrollTop > exp_height) {
        this.over = true;
      }
    },
    changeMenu(id) {
      this.text_course_chapter_id = id;
      this.getDetail();
    },
    prevPage(id) {
      this.text_course_chapter_id = id;
      this.getDetail();
    },
    nextPage(id) {
      this.text_course_chapter_id = id;
      this.getDetail();
    },
    getRecord() {
      let parmas = {
        text_course_id: this.text_course_id,
        text_course_chapter_id: this.text_course_chapter_id,
      };
      this.$axios
        .post(`/v1/textCourse/readRecord`, parmas, { useLog: true })
    },
  },
  // watch: {
  //   //监听表单
  //   over(newName){
  //     if(newName && this.timerOver){
  //       this.getRecord();
  //     }
  //   },
  //   timerOver(newName){
  //     if(newName && this.over){
  //       this.getRecord();
  //     }
  //   },
  // }
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}
.detail_content {
  background: #f6f6f6;
  .nav_header {
    width: 100%;
    height: 70px;
    background: #fff;
    .nav_box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      .leftMenu {
        img {
          width: 24px;
          height: 24px;
          display: block;
        }
        p {
          font-size: 16px;
          margin-left: 10px;
        }
        .limit-time{
          margin-left: 10px;
        }
      }
      .rightBtn {
        li {
          width: 104px;
          height: 40px;
          border-radius: 4px;
          border: 1px solid #ccc;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 400;
          color: #666;
          margin-left: 20px;
        }
      }
    }
  }
  .container {
    width: 1200px;
    min-height: 500px ;
    // background: #fff;
    border-radius: 10px;
    margin: 0px auto 0;
    padding: 10px 0px;
    display: flex;
    div.menu_list {
      width: 300px;
      max-height: 582px;
      height: auto;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 10px 30px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      li {
        line-height: 30px;
        font-size: 16px;
        cursor: pointer;
      }
      li:hover {
        color: #3ca7fa;
      }
      li.active {
        color: #3ca7fa;
      }
    }
    div.right_cont {
      background: #fff;
      margin-left: 20px;
      flex: 1;
      min-height: 200px;
      padding: 20px;
      font-size: 16px;
    }
  }
}
</style>